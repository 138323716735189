export const GENRE = [
  "Male", 
  "Female", 
  "Prefer not to say",
];

export const DOB_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const TITLES = [
  "Mr",
  "Mrs",
  "Ms",
  "Miss",
  "Air Marshall",
  "Air Vice Marshall",
  "Ambassador Female",
  "Ambassador Male",
  "Baron",
  "Baroness",
  "Brigadier",
  "Brigadier General",
  "Captain",
  "Chief",
  "Colonel",
  "Commander",
  "Comte",
  "Congressman",
  "Constable",
  "Consul General",
  "Conte",
  "Conti",
  "Count",
  "Countess",
  "Dame",
  "Datuk",
  "Dott",
  "Dr Female",
  "Dr Male",
  "Duchess",
  "Earl",
  "Earl Of",
  "Esq",
  "Field Marshall",
  "Firstname Lady",
  "Flt Lt",
  "Genera",
  "HE",
  "HE Ambassador",
  "HE Count",
  "HE Female",
  "HE Male",
  "Her Grace",
  "Her Highness",
  "Her Highness Of",
  "Herr",
  "HE Shaikh",
  "HE Sheikh",
  "HH",
  "HH Prince",
  "HH Princess",
  "HH Shaikh",
  "HH Sheikh",
  "HIH Grand Duke",
  "HIH Prince",
  "His Grace",
  "His Grace The Duke Of",
  "HM",
  "Hon",
  "HRH",
  "HRH Crown Prince",
  "HRH Prince",
  "HRH Princess",
  "HRH Sheikh",
  "Lady",
  "Lord",
  "Lt Colonel",
  "Lt Commander",
  "Major",
  "Marques",
  "Marquesa",
  "Marquess",
  "Marquis",
  "Mme",
  "Monsieur",
  "Prince",
  "Princess",
  "Prinz",
  "Professor",
  "Raja",
  "Senator",
  "Sheikh",
  "Sheikha",
  "Sir",
  "Tengku",
  "The Countess",
  "The Countess Of",
  "The Dowager",
  "The Dowager Viscountess",
  "The Duke",
  "The Duke Of",
  "The Earl",
  "The Earl Of",
  "The Hon",
  "The Hon (Female)",
  "The Hon Dr",
  "The Hon Lady",
  "The Hon Miss",
  "The Hon Mrs",
  "The Lady",
  "The Lord",
  "The Marquess",
  "The Marquess Of",
  "The Marquis",
  "The Marquis Of",
  "The Most Hon",
  "The Most Hon The Marquess Of",
  "The Rt Hon",
  "The Rt Hon Baroness",
  "The Rt Hon Countess",
  "The Rt Hon Field Marshall",
  "The Rt Hon Sir",
  "The Rt Hon The Earl",
  "The Rt Hon The Earl Of",
  "The Rt Hon The Lord",
  "The Rt Hon The Viscount",
  "The Rt Hon The Viscountess",
  "The Sirdar",
  "The Viscount",
  "Viscount",
  "Viscountess",
];

export const COUNTRIES = [
  "United States of America",
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo (the Democratic Republic of the)",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands [Malvinas]",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom of Great Britain and Northern Ireland",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];


export const NATIONALITIES = [
  'American',
'British',
'Afghan',
'Albanian',
'Algerian',
'Andorran',
'Angolan',
'Armenian',
'Austrian',
'Azerbaijani',
'Argentinian',
'Australian',
'Bahraini',
'Bangladeshi',
'Barbadian',
'Belarusian',
'Belizean',
'Beninese',
'Bermudian',
'Bhutanese',
'Bolivian',
'Bosnian',
'Botswanan',
'Bulgarian',
'Burkinese',
'Burundian',
'Canadian',
'Chinese',
'Colombian',
'Cuban',
'Cambodian',
'Cameroonian',
'Cape Verdean',
'Chadian',
'Chilean',
'Congolese',
'Costa Rican',
'Croat',
'Cypriot',
'Czech',
'Danish',
'Dominican',
'Djiboutian',
'Dominican',
'Dutch',
'Ecuadorean',
'Egyptian',
'Eritrean',
'Estonian',
'Ethiopian',
'Fijian',
'Finnish',
'French Polynesian',
'French',
'Gabonese',
'Gambian',
'Georgian',
'German',
'Guatemalan',
'Ghanaian',
'Greek',
'Grenadian',
'Guinean',
'Guyanese',
'Haitian',
'Honduran',
'Hungarian',
'Indian',
'Ireland',
'Israeli',
'Italian',
'Icelandic',
'Indonesian',
'Iranian',
'Iraqi',
'Japanese',
'Jamaican',
'Jordanian',
'Kazakh',
'Kenyan',
'North Korean',
'Kuwaiti',
'Latvian',
'Lebanese',
'Liberian',
'Libyan',
'Lithuanian',
'LUXEMBOURG',
'Madagascan',
'Malawian',
'Malaysian',
'Maldivian',
'Malian',
'Maltese',
'Mauritanian',
'Mauritian',
'Monacan',
'Mongolian',
'Montenegrin',
'Moroccan',
'Mozambican',
'Mexican',
'Namibian',
'Nepalese',
'New Zealand',
'Nicaraguan',
'Nigerien',
'Nigerian',
'Norwegian',
'Omani',
'Pakistani',
'Panamanian',
'Guinean',
'Paraguayan',
'Peruvian',
'Philippine',
'Polish',
'Portuguese',
'Qatari',
'Romanian',
'Rwandan',
'Salvadorean',
'Samoan',
'Saudi Arabian',
'Senegalese',
'Serbian',
'Sierra Leonian',
'Singaporean',
'Slovak',
'Slovenian',
'Slomoni',
'Somali',
'South African',
'South Korean',
'Spanish',
'Swedish',
'Swiss',
'Sri Lankan',
'Sudanese',
'Surinamese',
'Swazi',
'Taiwanese',
'Tajik',
'Thai',
'Togolese',
'Trinidadian',
'Tunisian',
'Turkish',
'Turkoman',
'Tuvaluan',
'Ugandan',
'Ukrainian',
'Emirati',
'Venezuelan',
'Vietnamese',
'Uruguayan',
'Uzbek',
'Vanuatuan',
'Yemeni',
'Zambian'
];

export const INDUSTRY = [
  "Advertising",
  "Agriculture",
  "Art & Design",
  "Banking",
  "Biotechnology",
  "Chemicals",
  "Communications",
  "Construction",
  "Consulting",
  "Creative",
  "Education",
  "Electronics",
  "Energy",
  "Engineering",
  "Entertainment",
  "Environmental",
  "Fashion",
  "Finance",
  "Food & Beverage",
  "Government",
  "Healthcare",
  "Hospitality",
  "Insurance",
  "Legal",
  "Machinery",
  "Manufacturing",
  "Media",
  "Not For Profit",
  "Property",
  "Recreation",
  "Recruitment",
  "Retail",
  "Shipping",
  "Technology",
  "Telecommunications",
  "Transportation",
  "Utilities",
  "Other",
];

export const INTERESTS = [
  "Art & Design",
  "Cigars",
  "Country Pursuits",
  "Dance",
  "Family Entertainment",
  "Fashion",
  "Film",
  "Food",
  "Literature",
  "Music / DJs",
  "Politics",
  "Sport",
  "Technology",
  "Theatre / Comedy / Musicals",
  "Travel",
  "Wellness & Beauty",
  "Wine & Spirits",
  "Yoga",
];
