import React, { useEffect, useState } from "react";
import { Paragraph, Box, Spinner } from "grommet";
import styled from "styled-components";
import { Controller} from "react-hook-form";
import FormField from "./FormField";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import Upload from "../assets/images/ic_upload.inline.svg";
import { useSelector, useDispatch } from "react-redux";
import { setFilename, clearFilename } from "../state/formSlice";

const StyledInput = styled.input`
  /* border: ${(props) => `1px solid ${props.error ? "#CE614A" : "#0030571f"}`};
  outline: ${(props) => (props.error ? "1px solid #CE614A" : "red")}; */
  padding: 21px 17px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #0056b4;
  background: transparent;

  &:focus {
    outline: ${(props) => `2px solid ${props.error ? "#CE614A" : "#0056b4"}`};
  }
`;

function FileInputComponent({ control,rootName,label,setValue,setError,...rest}) {
    const [file, setFile] = useState(false);
    const [uploading, setUploading] = useState(false);
    //const savedURL = useSelector((state) => state.form[`${rootName}`]);
    const savedURL = useSelector((state) => state.form[rootName]);
    const [errors, setErrors] = useState(false);
    const dispatch = useDispatch();

    const { getRootProps, getInputProps } = useDropzone({
      accept: ".pdf, .doc, .jpg, .jpeg, .png",
      maxSize: 10000000,
      onDrop: (acceptedFile, fileRejections) => {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrors({ type: err.message });
            }
            if (err.code === "file-invalid-type") {
              setErrors({ type: err.message });
            }
          });
        });
        if (acceptedFile[0]) {
          setFile(
            Object.assign(acceptedFile[0], {
              preview: URL.createObjectURL(acceptedFile[0]),
            })
          );
        }
      },
    });

    useEffect(() => {
      
      if (file) {
        dispatch(clearFilename({ name: `${rootName}` }));
        setUploading(true);
        let formData = new FormData();

        formData.append("assets", file, file.name);
        console.log(file);
        axios({
          method: "post",
          //url: `https://2fe8p9b1n1.execute-api.eu-west-2.amazonaws.com/dev/files`,
          url: `https://carriagehousepb.com/membership_uploads/`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(function (response) {
            setUploading(false);
            
            setValue(`${rootName}`, response.data.url, { shouldTouch: true, shouldValidate: true });

            dispatch(
              setFilename({ name: `${rootName}`, url: response.data.url })
            );
          })
          .catch(function (response) {
            setUploading(false);
            console.log(response);
          });
      }
    }, [file]);

    return control ? (
      <Controller
        name={`${rootName}`}
        control={control}
        rules={{ required: true }}
        defaultValue={savedURL}
        render={({
          field: { ref, value, name, onChange, ...inputProp },
          fieldState: { invalid, isTouched, isDirty, error },
          formState: { isValid },
        }) => {
          return (
            <FormField show={true} name={name} label={label} error={error} {...rest}>
              <Box
                direction="row"
                justify="between"
                align="center"
                {...getRootProps({ className: "dropzone" })}
                pad={{ horizontal: "24px" }}
                border={{
                  sides: "all",
                  size: error ? "2px" : "1px",
                  color: error ? "#CE614A" : "#0030571f",
                }}>
                <StyledInput name={name} style={{display: "none"}} />

                <StyledInput {...getInputProps()} />
                <Paragraph className="small straight">
                  {savedURL
                    ? "File accepted"
                    : uploading
                    ? "Uploading..."
                    : "Click to upload file"}
                </Paragraph>

                <Box style={{ opacity: savedURL ? 0.5 : 1 }}>
                  {uploading ? <Spinner /> : <Upload />}
                </Box>
              </Box>
            </FormField>
          );
        }}
      />
    ) : null;
}

export default FileInputComponent;
