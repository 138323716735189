import React, { useEffect, useState } from "react";
import { Paragraph, TextInput } from "grommet";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import FormField from "./FormField";
import { useSelector } from "react-redux";

const StyledInput = styled.input`
  border: ${(props) => `1px solid ${props.error ? "#CE614A" : "#0030571f"}`};
  outline: ${(props) => (props.error ? "1px solid #CE614A" : "none")};
  padding: 21px 17px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.5px;
  color: #0056b4;
  background: transparent;

  &:focus {
    outline: ${(props) => `2px solid ${props.error ? "#CE614A" : "#0056b4"}`};
  }
`;

function TextInputComponent({
  control,
  // defaultValue = "",
  validation={},
  name,
  label,
  disabled = false,
  optional = false,
  autoComplete = null,
  ...rest
}) {
  const defaultValue = useSelector((state) => state.form[name]);
  const [show, setShow] = useState(defaultValue !== "");

  return control ? (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: optional ? false : true, ...validation }}
      render={({
        field,
        fieldState: { invalid, isTouched, isDirty, error },
      }) => {
        return (
          <FormField
            setShow={setShow}
            show={show}
            error={error}
            name={name}
            label={label}
            field={field}
            {...rest}>
            <StyledInput
              style={{ opacity: disabled ? 0.5 : 1 }}
              disabled={disabled}
              error={error}
              autoComplete={autoComplete}
              placeholder={show ? null : label}
              {...field}
            />
          </FormField>
        );
      }}
    />
  ) : null;
}

export default TextInputComponent;
