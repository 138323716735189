import React, { useDebugValue, useEffect, useState } from "react";
import { Select, Box } from "grommet";
import styled from "styled-components";
import { Controller, useController } from "react-hook-form";
import FormField from "./FormField";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { DOB_MONTHS } from "../helpers/formLists";
import { saveDOBValue } from "../state/formSlice";

const StyledSelect = styled(Select)`
  &:focus {
    outline: none;
  }
`;

const renderDays = () => {
  return [...Array(31)].map((x, index) => (index + 1).toString());
};

const renderYears = () => {
  return [...Array(120)].map((x, index) => (index + 1901).toString());
};

function SelectInput({ rootName="", control, optional }) {

  const defaultValueDay = useSelector((state) => state.form[`${rootName}_day`]);
  const defaultValueMonth = useSelector((state) => state.form[`${rootName}_month`]);
  const defaultValueYear = useSelector((state) => state.form[`${rootName}_year`]);

  
  const [showDay, setShowDay] = useState(defaultValueDay);
  const [showMonth, setShowMonth] = useState(defaultValueMonth);
  const [showYear, setShowYear] = useState(defaultValueYear);

  const dispatch = useDispatch();

  const setDobValue = (value, name, element, onChange) => {
    console.log(value, name, element)
    dispatch(saveDOBValue({ value, name, element }));
    onChange(value);
  };

  return (
    <Box direction="row" gap="small">
      <Controller
        name={`${rootName}_month`}
        control={control}
        defaultValue={defaultValueMonth}
        rules={{ required: optional ? false : true }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
        }) => {
          return (
            <FormField
              setShow={setShowMonth}
              show={showMonth}
              name={name}
              error={error}
              label={"Month"}>
              <StyledSelect
                id={error ? "error" : null}
                ref={ref}
                name={name}
                options={DOB_MONTHS}
                placeholder={"Month"}
                onChange={(e) =>
                  setDobValue(e.target.value, rootName, "month", onChange)
                }
                value={value}
              />
            </FormField>
          );
        }}
      />

      <Controller
        name={`${rootName}_day`}
        control={control}
        defaultValue={defaultValueDay}
        rules={{ required: optional ? false : true }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
        }) => {
          return (
            <FormField
              setShow={setShowDay}
              show={showDay}
              name={name}
              error={error}
              label={"Day"}>
              <StyledSelect
                id={error ? "error" : null}
                ref={ref}
                name={name}
                options={renderDays()}
                placeholder={"Day"}
                onChange={(e) =>
                  setDobValue(e.target.value, rootName, "day", onChange)
                }
                value={value}
              />
            </FormField>
          );
        }}
      />

      <Controller
        name={`${rootName}_year`}
        control={control}
        defaultValue={defaultValueYear}
        rules={{ required: optional ? false : true }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
        }) => {
          return (
            <FormField
              setShow={setShowYear}
              show={showYear}
              name={name}
              error={error}
              label={"Year"}>
              <StyledSelect
                id={error ? "error" : null}
                ref={ref}
                name={name}
                options={renderYears()}
                placeholder={"Year"}
                onChange={(e) =>
                  setDobValue(e.target.value, rootName, "year", onChange)
                }
                value={value}
              />
            </FormField>
          );
        }}
      />
    </Box>
  );
}

export default SelectInput;
