import React, { useEffect, useState } from "react";
import { FormField, Paragraph, TextInput } from "grommet";
import styled from "styled-components";

const StyledFormField = styled(FormField)`
  min-height: 100px;
  position: relative;
  top: 25px;
  /* border: 1px solid red; */

  label {
    z-index: 1;
    font-size: 12px;
    color: ${(props) => `${props.error ? "#CE614A" : "#669ad2"}`};
    margin: 0 24px;
    padding: 0 5px;
    display: flex;
    align-self: start;
    background: white;
    position: absolute;
    top: -12px;
    left: -9px;
  }
`;

function FormFieldComponent({
  show = () => {},
  setShow = () => {},
  field,
  name,
  info,
  label,
  error,
  children,
}) {
  return (
    <StyledFormField
      name={name}
      onFocus={() => setShow(true)}
      onBlur={() => field?.value === "" && setShow(false)}
      htmlFor="text-input-id"
      label={show ? label : null}
      info={show && !error?.type ? info : null}
      error={
        error?.type
          ? error?.type === "pattern"
            ? "Invalid email"
            : "Required"
          : null
      }>
      {children}
    </StyledFormField>
  );
}

export default FormFieldComponent;
